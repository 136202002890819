<script>
import { login, sendCodeSMS } from '@/api/loginServices'

export default {
  name: 'Index',
  data() {
    return {
      phone: '',
      code: '',
      resendTMinus: 0,
    }
  },
  methods: {
    onSendCodeSMS: async function () {
      if (this.phone) {
        if (/1\d{10}/.test(this.phone)) {
          await sendCodeSMS(this.phone)
          this.$toast('发送成功')
          this.resendTMinus = 60
          let codeInterval = setInterval(() => {
            if (this.resendTMinus <= 0) {
              clearInterval(codeInterval)
            } else {
              this.resendTMinus -= 1
            }
          }, 1000)
        } else {
          this.$toast('手机号不合法')
        }
      } else {
        this.$toast('手机号不能为空')
      }
    },
    onLogin: async function () {
      if (this.phone && this.code) {
        const res = await login({
          mobile: this.phone,
          code: this.code,
          zone: 1,
        }).catch(err => {
          this.$toast(err.data.msg)
        })
        localStorage.setItem('parentPhone', res.data.mobile)
        await this.$router.replace('/')
      } else {
        this.$toast('请输入完整信息')
      }
    },
  },
}
</script>

<template>
  <div class="phone-login">
    <div class="wrapper">
      <div class="title">请登录</div>
      <div class="phone">
        <label for="">手机号</label>
        <van-field v-model="phone" type="tel" placeholder="请输入手机号"/>
      </div>
      <div class="code">
        <label for="">验证码</label>
        <van-field v-model="code" :maxlength="4" type="number" placeholder="请输入验证码">
          <template #button>
            <van-button :disabled="resendTMinus > 0" class="code-btn"
                        plain size="small" type="primary"
                        @click="onSendCodeSMS">
              {{ resendTMinus > 0 ? `${ resendTMinus }s` : '发送验证码' }}
            </van-button>
          </template>
        </van-field>
      </div>
      <van-button class="login-button" type="primary" @click="onLogin">
        登录
      </van-button>
    </div>
  </div>
</template>

<style scoped lang="less">
.phone-login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url("../../assets/phoneLogin_bg.png") no-repeat center;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    box-sizing: border-box;
    width: 6.8rem;
    height: 8.22rem;
    margin-top: 2rem;
    padding: 0 0.52rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 2px 13px 0px rgba(203, 231, 219, 1);
    border-radius: 0.24rem;
    > div {
      width: 100%;
    }
    .title {
      padding-top: 0.44rem;
      font-size: 0.48rem;
      color: #000000;
      text-align: center;
      font-weight: bold;
    }
    .phone {
      margin-top: 0.48rem;
      font-size: 0.28rem;
      color: #999999;
      .van-field {
        height: 1.12rem;
        margin-top: 0.16rem;
        background: #F9FBFE;
        border: 1px solid rgba(204, 204, 204, 1);
        border-radius: 0.08rem;
        /deep/ .van-field__body {
          height: 100%;
        }
      }
    }
    .code {
      .phone;
      margin-top: 0.16rem;
      .code-btn {
        border: none;
        background: transparent;
        color: #45ACA0;
        &.van-button--disabled {
          color: #999;
        }
      }
    }
    .login-button {
      margin-top: 1.16rem;
      width: 100%;
      height: 1.12rem;
      border-radius: 0.56rem;
      background: #45ACA0;
      border-color: #45ACA0;
      box-shadow: 0px 2px 8px 0px rgba(69, 172, 160, 0.33);
      font-size: 0.4rem;
      color: #FFFFFF;
      font-weight: bold;
    }
  }
}
</style>
